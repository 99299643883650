// Imports

import { put, select, takeLatest, take, call } from 'redux-saga/effects';
import { parseJwt, keycloakInit } from 'drb-commons-front';
import { getConfig } from '../config/configDucks';
import { extractPermissions } from '../banners/bannersUtils';
import debugFactory from 'debug';
import { STRATEGY_SSO_LOGIN_COMPULSORY } from '../reference/strategies';
import { addError, addInfo } from '../messages/messagesDucks';
import { clearState } from '../banner/bannerActionsCreators';
import { getBannerSetUid } from '../banners/bannersSelectors';
import {
  BANNERSET_LOAD_FROM_URL_PARAMS_SUCCESS,
  BANNERSET_LOAD_FROM_URL_PARAMS_FAILURE,
} from '../banners/bannersActions';
import {
  loadBannerSetFromUrlParams,
  setBannerSetPermissions,
  setBannerSetLock,
} from '../banners/bannersActionsCreators';
import { openOpenBannerSetDialogAtStart } from '../open-banner-set/openBannerSetDucks';
import { getEntitiesRequested, getDCORequested } from '../entities/entitiesActionCreators';
import { getUserTheme, LAYOUT_SET_THEME, appLoaded } from '../layout/layoutDucks';
import { loadAllFonts } from '../resources/fonts';
import fetchNuxeo from '../api/fetchNuxeo';
import { noOp } from '../reference/sagaHelpers';
import { getUserFonts } from '../shared-selectors/sharedSelectors';

const debug = debugFactory('adbuilder:securityDucks');

// Actions

const SECURITY_AUTH_INIT = 'adbuilder/SECURITY_AUTH_INIT';
const SECURITY_AUTH_SUCCESS = 'adbuilder/SECURITY_AUTH_SUCCESS';
export const SECURITY_AUTH_ERROR = 'adbuilder/SECURITY_AUTH_ERROR';
export const SECURITY_AUTH_RESET = 'adbuilder/SECURITY_AUTH_RESET';

// Action creators

export const initAuth = () => ({
  type: SECURITY_AUTH_INIT,
});

export const authSuccess = (
  { user: { username, firstName, lastName, email }, token, refreshToken, idToken, timeSkew },
  workspacePath,
) => ({
  type: SECURITY_AUTH_SUCCESS,
  payload: {
    user: { username, firstName, lastName, email, workspacePath },
    token,
    refreshToken,
    idToken,
    timeSkew,
  },
});

export const authError = () => ({
  type: SECURITY_AUTH_ERROR,
});

export const authReset = () => ({
  type: SECURITY_AUTH_RESET,
});

// Selectors

export const getTokens = ({ security: { token, refreshToken, idToken, timeSkew } }) => ({
  token,
  refreshToken,
  idToken,
  timeSkew,
});

// Sagas
export function* saveWorkspaceSaga({ auth, entity_attribute }) {
  const workspacePath = yield call(fetchNuxeo.userWorkspace, entity_attribute);
  if (!workspacePath) {
    throw new Error(`no workspace exists with this token`);
  }
  yield put(authSuccess(auth, workspacePath));
}

export function* getBannerStatePermissionsSaga() {
  try {
    const bannerSetId = yield select(getBannerSetUid);
    // if no banner-set id, we don't do anything
    if (!bannerSetId) return;
    // we try to fetch the banner-set with its id
    const bannerSet = yield call(fetchNuxeo.bannerSetPermissions, bannerSetId);
    if (!bannerSet) {
      yield put(clearState());
      yield put(addInfo('app.no-right-on-current-bannerset'));
    } else {
      yield put(setBannerSetPermissions(extractPermissions(bannerSet)));
      yield put(setBannerSetLock(bannerSet.lockOwner));
    }
  } catch (e) {
    console.error('cannot reset banner state if needed');
  }
}

export function* renewJSESSIONID({ user: { email } }, tokenEmail) {
  // https://jira.mylocalad.com/browse/DBE-622
  debug('renewJSESSIONID', email, tokenEmail);
  // compare the sessions and logout if sessions are different
  if (email !== tokenEmail) {
    yield call(fetchNuxeo.logout);
  }
  // get the new JSESSIONID cookie
  yield call(fetchNuxeo.me);
}

export function* initAuthSaga() {
  try {
    // init keycloak
    const { ssoUrl, ssoRealm, ssoClientId } = yield select(getConfig);
    const { token, refreshToken, idToken, timeSkew } = yield select(getTokens);

    const auth = yield call(keycloakInit, {
      keycloakConfig: { url: ssoUrl, realm: ssoRealm, clientId: ssoClientId },
      initConfig: { token, refreshToken, idToken, timeSkew },
      debug: process.env.NODE_ENV === 'test' ? noOp : debug,
      loginCompulsory: STRATEGY_SSO_LOGIN_COMPULSORY,
    });
    debug && debug({ auth });
    // reset if not authenticated
    if (!auth.authenticated) {
      yield put(authReset());
      return;
    }
    const { entity_attribute, email } = yield call(parseJwt, auth.token);

    // renew the JSESSIONID if needed
    yield call(renewJSESSIONID, auth, email);

    // save work space for saving newly created bannersets
    yield call(saveWorkspaceSaga, { auth, entity_attribute });

    // get entities
    yield put(getEntitiesRequested());
    yield put(getDCORequested());

    // reset the banner state if the owner has no right on it
    yield call(getBannerStatePermissionsSaga);

    // load theme
    yield put(getUserTheme());
    yield take(LAYOUT_SET_THEME);

    // load url params if any
    yield put(loadBannerSetFromUrlParams());
    yield take([BANNERSET_LOAD_FROM_URL_PARAMS_SUCCESS, BANNERSET_LOAD_FROM_URL_PARAMS_FAILURE]);

    yield put(openOpenBannerSetDialogAtStart());

    // app loaded
    yield put(appLoaded());
  } catch (e) {
    console.error('Authentication error', e);
    yield put(authError());
    yield put(addError('app.authentication-error'));
  }

  // load fonts (put after everything because if there is an error while loading fonts, it should not stop the app from loading)
  const userFonts = yield select(getUserFonts);
  yield call(loadAllFonts, userFonts);
}

export function* saga() {
  yield takeLatest(SECURITY_AUTH_INIT, initAuthSaga);
}

// Reducer

export const initialState = {
  authenticated: false,
  user: null,
  token: null,
  refreshToken: null,
  idToken: null,
  timeSkew: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SECURITY_AUTH_SUCCESS: {
      return {
        ...action.payload,
        authenticated: true,
      };
    }
    case SECURITY_AUTH_ERROR:
    case SECURITY_AUTH_RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
